@mixin dark-mode() {
  color-scheme: dark;
  --light: ;
  --dark: initial;
}

@mixin light-mode() {
  color-scheme: light;
  --light: initial;
  --dark: ;
}

:root {
  @media (prefers-color-scheme: dark) {
    @include dark-mode();
  }

  @media (prefers-color-scheme: light) {
    @include light-mode();
  }

  &[data-theme="dark"] {
    @include dark-mode();
  }

  &[data-theme="light"] {
    @include light-mode();
  }
}

[data-no-transition] {
  &,
  *,
  *::before,
  *::after {
    transition: none !important;
  }
}
