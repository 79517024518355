.custom-props__label {
  margin: 0;
  margin-block-end: var(--space-2);
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.custom-props__list {
  dt {
    color: var(--color-neutral-text);
    font-size: var(--font-size-xs);
  }

  dd {
    color: var(--color-neutral-text-contrast);
    font-size: var(--font-size-sm);
  }
}

.custom-props__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-props__links {
  color: inherit;
  text-underline-offset: 5px;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;

  &:hover {
    text-decoration-style: solid;
  }
}
