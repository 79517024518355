.theme-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.theme-switcher__label {
  position: relative;
  padding: var(--space-2);
  border-radius: var(--rounded-lg);
  color: var(--color-neutral-text);
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 100%;
    z-index: -1;
    border-radius: var(--rounded-lg);
    background-color: var(--color-neutral-bg);
    opacity: 0;
    transition: all 200ms ease;
  }

  &:hover::after,
  input:focus-visible + &::after {
    inset: 0;
    opacity: 1;
  }

  input:checked + & {
    color: var(--color-primary-text);

    svg {
      fill: var(--color-primary-bg);
    }
  }
}
