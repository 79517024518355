.grouped-links__label {
  margin: 0;
  margin-block-end: var(--space-2);
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
}

.grouped-links__link {
  max-inline-size: 100%;
  display: inline-flex;
  align-items: center;
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  text-decoration: none;
  text-underline-offset: 5px;

  svg {
    inline-size: 16px;
    block-size: 16px;
    flex-shrink: 0;
    box-sizing: content-box;
    padding-inline-end: var(--space-2);
    opacity: 0.8;
  }

  span {
    // Firefox + Safari don't display text-decoration with offset if the element
    // uses overflow: hidden, even though the deocration is is on the parent element.
    // => Padding needs to be added here and not on the parent element.
    padding-block: var(--space-1);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    text-decoration: underline;
  }
}
