
// DO NOT EDIT THIS FILE DIRECTLY.
// Update it by running "npm run themes:generate"

:root {
  --font-family-default: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-fluid-x2: clamp(0.79rem, calc(0.76rem + 0.17vw), 0.89rem);
  --font-size-fluid-x1: clamp(0.89rem, calc(0.85rem + 0.19vw), 1rem);
  --font-size-fluid-0: clamp(1rem, calc(0.96rem + 0.22vw), 1.13rem);
  --font-size-fluid-1: clamp(1.13rem, calc(1.08rem + 0.24vw), 1.27rem);
  --font-size-fluid-2: clamp(1.27rem, calc(1.21rem + 0.28vw), 1.42rem);
  --font-size-fluid-3: clamp(1.42rem, calc(1.36rem + 0.31vw), 1.6rem);
  --font-size-fluid-4: clamp(1.6rem, calc(1.53rem + 0.35vw), 1.8rem);
  --font-size-fluid-5: clamp(1.8rem, calc(1.72rem + 0.39vw), 2.03rem);
  --font-size-fluid-6: clamp(2.03rem, calc(1.94rem + 0.44vw), 2.28rem);
  --font-size-fluid-7: clamp(2.28rem, calc(2.18rem + 0.5vw), 2.57rem);
  --font-size-fluid-8: clamp(2.57rem, calc(2.45rem + 0.56vw), 2.89rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --shadow-xs: 0 1px 3px rgba(100, 100, 100, 0.09);
  --shadow-sm: 0 1px 5px rgba(100, 100, 100, 0.05);
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px rgba(100, 100, 100, 0.05), 0 0 40px rgba(100, 100, 100, 0.015);
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px rgba(100, 100, 100, 0.14);
  --shadow-xl: 0 4px 12px rgba(100, 100, 100, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --shadow-xxl: 0 24px 38px 3px rgba(100, 100, 100, 0.16), 0 9px 86px 8px rgba(100, 100, 100, 0.1), 0 11px 15px -7px rgba(100, 100, 100, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --color-backdrop: var(--light, rgba(0, 0, 0, 0.25)) var(--dark, rgba(0, 0, 0, 0.5));
  --color-background: var(--light, #fff) var(--dark, #121113);
  --color-surface: var(--light, #fff) var(--dark, #121113);
  --color-primary-mark: var(--light, #8000db2a) var(--dark, #be5cfd51);
  --color-primary-bg-subtle: var(--light, #fbf7fe) var(--dark, #1e1523);
  --color-primary-bg: var(--light, #f7edfe) var(--dark, #301c3b);
  --color-primary-bg-hover: var(--light, #f2e2fc) var(--dark, #3d224e);
  --color-primary-bg-active: var(--light, #ead5f9) var(--dark, #48295c);
  --color-primary-line-weak: var(--light, #ead5f9) var(--dark, #48295c);
  --color-primary-line: var(--light, #e0c4f4) var(--dark, #54346b);
  --color-primary-border: var(--light, #d1afec) var(--dark, #664282);
  --color-primary-border-hover: var(--light, #be93e4) var(--dark, #8457aa);
  --color-primary-focus-ring: var(--light, #be93e4) var(--dark, #8457aa);
  --color-primary-bg-solid: var(--light, #8e4ec6) var(--dark, #8e4ec6);
  --color-primary-bg-solid-hover: var(--light, #8347b9) var(--dark, #9a5cd0);
  --color-primary-text: var(--light, #8145b5) var(--dark, #d19dff);
  --color-primary-text-contrast: var(--light, #402060) var(--dark, #ecd9fa);
  --color-primary-placeholder: var(--light, #8e4ec6) var(--dark, #8e4ec6);
  --color-neutral-bg-subtle: var(--light, #faf9fb) var(--dark, #1a191b);
  --color-neutral-bg: var(--light, #f2eff3) var(--dark, #232225);
  --color-neutral-bg-hover: var(--light, #eae7ec) var(--dark, #2b292d);
  --color-neutral-bg-active: var(--light, #e3dfe6) var(--dark, #323035);
  --color-neutral-line-weak: var(--light, #e3dfe6) var(--dark, #323035);
  --color-neutral-line: var(--light, #dbd8e0) var(--dark, #3c393f);
  --color-neutral-border: var(--light, #d0cdd7) var(--dark, #49474e);
  --color-neutral-border-hover: var(--light, #bcbac7) var(--dark, #625f69);
  --color-neutral-focus-ring: var(--light, #bcbac7) var(--dark, #625f69);
  --color-neutral-bg-solid: var(--light, #8e8c99) var(--dark, #6f6d78);
  --color-neutral-bg-solid-hover: var(--light, #84828e) var(--dark, #7c7a85);
  --color-neutral-text: var(--light, #65636d) var(--dark, #b5b2bc);
  --color-neutral-text-contrast: var(--light, #211f26) var(--dark, #eeeef0);
  --color-neutral-placeholder: var(--light, #8e8c99) var(--dark, #6f6d78);
  --callout-blue-text: var(--light, #006dcbf2) var(--dark, #70b8ff);
  --callout-blue-bg: var(--light, #008ff519) var(--dark, #0077ff3a);
  --callout-mint-text: var(--light, #007763fd) var(--dark, #67ffded2);
  --callout-mint-bg: var(--light, #00d29e22) var(--dark, #00fff61d);
  --callout-green-text: var(--light, #00713fde) var(--dark, #46fea5d4);
  --callout-green-bg: var(--light, #00a43319) var(--dark, #22ff991e);
  --callout-orange-text: var(--light, #cc4e00) var(--dark, #ffa057);
  --callout-orange-bg: var(--light, #ff9c0029) var(--dark, #fb6a0025);
  --callout-red-text: var(--light, #c40006d3) var(--dark, #ff9592);
  --callout-red-bg: var(--light, #f3000d14) var(--dark, #ff173f2d);
  --callout-purple-text: var(--light, #52009aba) var(--dark, #d19dff);
  --callout-purple-bg: var(--light, #8e00f112) var(--dark, #c150ff2d);
  --callout-gray-text: var(--light, #0000009b) var(--dark, #ffffffaf);
  --callout-gray-bg: var(--light, #00000006) var(--dark, #ffffff09)
};

@import './app';
