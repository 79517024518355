.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-2);
  padding-block: var(--space-1);
  padding-inline: var(--space-2);
  border: 0;
  border-radius: var(--rounded-md);
  background: transparent;
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--color-primary-text);
  transition: all 300ms ease;
  text-decoration: none;

  &:hover {
    background-color: var(--color-primary-bg-hover);
  }

  svg {
    fill: transparent;
    transition: all 300ms ease;
  }

  &[aria-pressed="true"] {
    background-color: var(--color-primary-bg-active);

    svg {
      fill: currentColor;
    }
  }
}
